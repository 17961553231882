.order-form,
.thanks {
    display: flex;
    flex-direction: column;
    max-width: 63.4375rem;
    height: 36rem;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background: #fff;
}

#order-now {
    display: flex;
}
.modal-body {
    padding: 0 !important;
}

.thank-order,
.job-hunt {
    background-size: cover;
    background-repeat: no-repeat;
    padding: 1.5rem 1.5rem 0;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 85vh;
}

.job-hunt {
    background: url("../images/jobhunt.png");
}

.thank-order {
    background: url("../images/thank-order.png");
}

.supercharge {
    color: #2ad883;
    font-size: 1.5625rem;
    font-family: Inter;
    font-weight: 700;
    text-align: left;
}

.hunt-form {
    padding: 2rem;
    background: #fff;
}

.hunt-form-form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2rem 0 6rem;
}

.hunt-form-form .order-inputs {
    width: 100% !important;
    border-radius: 5px;
    border: 1px solid #ced4da;
    color: #000;
    font-size: 1.25rem;
    padding: 10px;
}

@media (min-width: 992px) {
    .thanks,
    .order-form {
        flex-direction: row;
        justify-content: space-between;
    }

    .job-hunt,
    .hunt-form,
    .thank-order {
        width: 50vw;
    }
}
.radio-div {
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
}

.radio-div input {
    margin-right: 0.8rem;
    transform: scale(2);
}

/* .radio-div input[type="radio"] {
    margin-right: -4rem !important;
    margin-left: -4.7rem !important;
} */

.order-now-btn {
    width: 100%;
    color: #fff;
    font-size: 1.375rem;
    font-weight: 700;
    border-radius: 5px;
    background: #000;
    padding: 10px;
}

.thanks-title,
.pop-up-title {
    color: #000;
    font-size: 1.5625rem;
    font-family: Inter;
    font-weight: 900;
}

.pop-up-title {
    text-align: center;
}

.thanks-title {
    text-align: left;
}

#thanks {
    display: flex;
}

.thank-title {
    color: #2ad883;
    text-align: center;
    margin: 0 auto;
    font-size: 2rem;
    max-width: 300px;
    font-family: Inter;
    font-weight: 700;
}

.hunting-kit {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.check-list {
    font-weight: 500;
    margin-left: 2rem;
}

.check-list span {
    color: #2ad883;
}

.close {
    font-size: 15px !important;
}

.invalid-feedback-cus {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545;

}
.modal-width{
    max-width: 85% !important;
}

@media(max-width:991px) {
    .free-job-hunt {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .main-title{
        text-align: center;
    }

    .about-section{
        align-items: center;
        justify-content: center;
    }
    .green-par{
        text-align: center;
    }
    .about-title{
        text-align: center;
        margin-top: 30px;
    }
    .modal-width{
        max-width: 100% !important;
    }

    .job-hunt{
        display: none;
    }
    #order-now{
        padding-bottom: 20px;
    }
    .thank-order{
        display: none;
    }
    .single-vistor-slide{
        flex-direction: column;
    }
}
