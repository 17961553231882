.single-vistor-slide {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1101px;
    margin: 5rem auto;
}

.visiter-slide-ul {
    font-size: 20px;
    list-style-type: none;
    text-align: right;
    max-width: 464px;
}

.visitor-slide-title {
    margin-top: 4rem;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: #2AD883 !important;
    border-radius: 50px;
}

.carousel .carousel-control-prev{
    bottom: -20rem;
    right: 50px;
    left: unset;
}

.carousel .carousel-control-next {
    /* left: 50px; */
    /* right: unset; */
    bottom: -20rem;
  }