.menu-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 5rem;
    position: fixed;
    top: 5%;
    right: 5%;
}

.register-mobile {
    font-size: 15px;
    font-weight: 700;
    line-height: 1.5;
    color: #171C22;
    background: #2AD883;
    border-radius: 3px;
    padding: .1rem 1rem;
}
.menu-items a {
    font-weight: 500;
    font-size: 15px;
    line-height: 1.5;
    margin-bottom: 1rem;
}

a, a:hover, a:focus {
    text-decoration: none;
    color: #fff;

}

.hamburger-button {
    padding: 1rem;
    margin-left: auto;
}


.hamburger-menu {
    display: none;
}


@media(max-width:991px) {
    .hamburger-menu {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: -22px;
    }
}

.top {
    background: #000;
}

.show-mobile-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #171C22;
    color: whitesmoke;
    font-weight: 800;
    font-size: 20px;
    line-height: 1.5;
    width: 100%;
    height: 100%;
    padding: 0 20px 20px;
    
}

#show-mobile-menu {
    display: none;
}

@media(max-width:991px) {
    .logo {
        width: 102px !important;
    }
}

.close {
    font-size: 30px;
    text-align: right;
}

.register-drop-btn {
    max-width: 314px !important;
    color: #171C22;
    background: #2AD883;
    border-radius: 3px;
    padding: .5rem 1rem;
    margin: 0 auto;
}

.menu-drop {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

.top {
    height: 350%;
    background: transparent;
  }
  
.bottom {
height: 50%;
}

.fa-bars {
    font-size: 30px;
    color: #fff;
}