*,
html,
body {
    font-family: 'Roboto', sans-serif;
    scroll-behavior: smooth;
}

h2 {
    font-size: 32px;
}

p {
    font-size: 18px;
    text-align: justify;
}

#testimonial,
#offer,
#products {
    scroll-behavior: smooth;
}

.tool-kit {
    color: #2AD883;
    padding-top: 2rem;
}

.get-it-now {
    font-weight: 600;
    color: #171C22;
    border: none;
    background: #2AD883;
    padding: 10px 2rem;
    border-radius: 5px;
}

.nav-container {
    z-index: 9999;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
    padding-top: 1rem;
}

.term-nav {
    background: rgba(0, 0, 0, .7);
}

.prov-nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    font-weight: 500;
    font-size: 25px;
    line-height: 29px;
    color: #FFFFFF;
    width: 60%;
}

.nav-list li {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
}


.nav-right {
    list-style-type: none;
    display: flex;
    justify-content: flex-end !important;
}

.nav-right li {
    margin-right: 1rem;
}

.nav-bg {
    background: rgba(0, 0, 0, .7);
    background-image: url("../images/header.png");
    background-size: cover;
}

.helpline {
    text-align: right;
    font-weight: 500;
}

.helpline-off {
    display: none;
}

@media(max-width:991px) {
    .nav-container ul li {
        padding-top: 1rem;
    }

    .nav-right,
    .nav-list {
        display: none;
    }
}

.register-btn {
    background: transparent;
    border: 3px solid #FFFFFF;
    border-radius: 10px;
    padding: .5rem 1.5rem;
    color: #FFFFFF;
}


.main-banner {
    background: url('../images/main-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    /* margin-top: -6rem; */
    /* padding-top: 10%; */
}

.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFFFFF;
}


.man-running {
    width: 45%;
}

.main-text {
    max-width: 598px;
}


@media(min-width:992px){
    .prov-nav,
    .nav-container ul {
        flex-direction: row;
        justify-content: space-between;
    }

    .nav-container ul{
        min-width: 50%;
    }

    .main-container {
        flex-direction: row;
        justify-content: space-between;
    }

    .main-banner {
        height: 100vh;
    }
}


.main-title {
    font-weight: 600;
    /* font-size: 32px; */
}

.main-title span {
    color: #2AD883;
}

.text-line {
    color: #FFFFFF;
}

@media(max-width:991px) {
    .main-banner {
        margin-top: -14rem;
        padding-top: 50vh;
    }

    .main-text {
        font-size: 40px;
    }

}

.section-title {
    color: #2AD883;
}

.offer-container {
    background: #20272F;
    /* padding: 2rem 0; */
}

.section-title span {
    background: #2AD883;
    border-radius: 10px;
    color: #000000;
    padding: 2px 10px;
}

.about-section,
.content-section {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 4rem 0;
}

.offer-img {
    margin-top: 5rem;
    max-width: 100%;
    height: auto;
    overflow: hidden;
}

.about-img {
    max-width: 50%;
}

.offer-text {
    margin-top: 3rem;
}

@media (min-width:769px) {
    .about-section,
    .content-section {
        flex-direction: row;
        justify-content: space-between;
    }

    .about-section {
        align-items: flex-end;
        align-items: center;
    }

    .offer-img {
        margin-top: 0;
    }
}

.green-par {
    color: #2AD883;
    font-weight: 800;
    font-size: 25px;
    margin-top: 0;
    padding-top: 0;
}

.video-section {
    background: #20272F;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 5rem 0;
}

.video-width {
    max-width: 1091px;
}

.video-text {
    margin: 2rem 0 4rem;
}

@media(max-width:768px) {
   iframe {
    width: 100%;
   }
}

.social-section {
    display: flex;
    justify-content: space-around;
    width: 232px;
    /* margin: 2rem auto; */
}

.social-section a img {
    width: 30px;
}

.footer {
    background: #000000;
    color: #FFFFFF;
    padding: 3rem 0 .5rem 0;
}

.logo-footer {
    width: 229px;
    height: 70px;
}


.footer-title {
    font-weight: 800;
    font-size: 30px;
    margin-bottom: 2rem;
    text-transform: capitalize;
}

.useful-title {
    color: #2AD883;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Poppins';
}

.footer-p {
    padding: .5rem 0;
    font-weight: 300;
    font-size: 18px;
    font-family: 'Poppins';
}
.footer-title span {
    color: #2AD883;
    background: #000000;
    padding: 0 1rem;
    border-radius: 8px;
}

.footer-btn {
    font-weight: 500;
    font-size: 25px;
    border: 3px solid #2AD883;
    border-radius: 5px;
    background-color: #2AD883;
    padding: .2rem 1.5rem;
}

.footer-col {
    margin-top: 1rem;
}

.footer-col ul {
    list-style-type: none;
    font-weight: 300;
    font-size: 18px;
}

.copyright {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    color: #2AD883;
    text-align: center;
    margin-top: 2rem;
}

.job-slider {
    background: #171C22;
    padding: 5rem 0;
    text-align: center;
}

.slider-section {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
}


.slider-section img {
    max-width: 573.14px;
}

.slider-caption {
    text-align: left;
    max-width: 400px;
    position: relative;
}

.do-slider {
    display: flex;
    justify-content: space-around;
    align-items: center;
}


.slider-text {
    max-width: 500px;
}

/* Remove carousel arrows */
/* .carousel-control-prev-icon,
.carousel-control-next-icon, */
.carousel-indicators {
    display: none !important;
}



.slider-img {
    max-Height: 415px;
}



/* @media(min-width: 992px) and (max-width:1199px) {
    .offer-text-section {
        margin-left: 10%;
    }

    .offer-text {
        font-size: 16px;
        margin-top: 1rem;
    }

    p {font-size: 16px;}
} */

@media(min-width: 992px) {
    .offer-text-section {
        margin-left: 2%;
    }
}


.carousel-container {
  overflow: hidden;
}

@media(max-width:768px){
    .do-slider {
        flex-direction: column;
    }
}