.product-card {
    /* max-width: 18rem; */
    border: none !important;
    margin: 1rem auto;
}

.product-body {
    background: #2AD883;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.product-title {
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    height: 50px;
}

.product-text {
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    height: 160px;
}

.price-before {
    text-decoration: line-through;
}

.price-now {
    font-weight: 700;
    text-align: center;
}

.buy-now-btn {
    padding: 0.2rem 3rem;
    font-weight: 500;
    color: #171C22;
    background: #2AD883;
    border: 3px solid #20272F;
    border-radius: 50px;
    transition: all 2s ease;
}

.buy-now-btn:hover,
.buy-now-btn:focus {
    color: #2AD883;
    background: #20272F;
}

.available-products {
    margin-top: 3rem;
}

.product-single-top {
    background: #20272F;
    color: #FFFFFF;
    padding: 10rem 0 4rem;
}

.single-title {
    color: #2AD883;
    font-family: Roboto;
    
font-weight: 700;
font-size: 50px;
}

.single-price span {
    border: 2px solid #2AD883;
    padding: .5rem 2rem;
    border-radius: 5px;
    margin-right: 1rem;
}

.short-desc,
.package-desc {
    font-size: 20px;
    font-family: Roboto;
    font-weight: 700;
}

.package-desc {
    color: #2AD883;
}

.ready-to-win-it button {
    background: transparent;
    color: #2AD883;
    border: #2AD883 2px solid;
    padding: .3rem 2rem;
    margin-left: 2rem;
    border-radius: 50px;
}

.total-price {
    color: #2AD883;
    margin-top: 3rem;
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 700;
}

.sticky-image-div {
    position: sticky;
    top: 0;
}

.testimonial {
    background-color: #FFFFFF !important;
    padding-top: 3rem;
    z-index: 99999;
    
}

.details-cont {
    display: flex;
    flex-direction: column;
}

@media (min-width: 992px) {
    
/* .product-single-top {
    position: sticky;
    top: -30%;
    z-index: -1;
} */

    .details-cont {
        flex-direction: row;
        justify-content: space-between;
    }

    .details-right {
        margin-left: 5rem;
    }
}
